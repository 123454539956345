@font-face {
  font-family: 'Nyght';
  src: url('./assets/fonts/NyghtSerif-Regular.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-RegularItalic.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-MediumItalic.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-Medium.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-LightItalic.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-Light.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-DarkItalic.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-Dark.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-BoldItalic.ttf') format('truetype');
  src: url('./assets/fonts/NyghtSerif-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'geom';
  src: url('./assets/fonts/cmgeom-regular.ttf') format('truetype');
}
@tailwind base;
@tailwind components;
@tailwind utilities;



.grecaptcha-badge { 
  visibility: hidden;
}
.campain-card {
  transition: all ease .3s;
  cursor: pointer;
}
.campain-card:hover {
  transition: all ease .3s;
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.15);
}