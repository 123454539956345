 svg .svg-elem-1 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
  }
  
  svg.active .svg-elem-1 {
    fill: rgb(255, 214, 0);
  }
  
  svg .svg-elem-2 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.8s;
  }
  
  svg.active .svg-elem-2 {
    fill: rgb(255, 214, 0);
  }
  
  svg .svg-elem-3 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.1s;
  }
  
  svg.active .svg-elem-3 {
    fill: rgb(255, 214, 0);
  }
  
  /* svg .svg-elem-4 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.4s;
  }
  
  svg.active .svg-elem-4 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-5 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.7s;
  }
  
  svg.active .svg-elem-5 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-6 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2s;
  }
  
  svg.active .svg-elem-6 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-7 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.3s;
  }
  
  svg.active .svg-elem-7 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-8 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.6s;
  }
  
  svg.active .svg-elem-8 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-9 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.9s;
  }
  
  svg.active .svg-elem-9 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-10 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.1999999999999997s;
  }
  
  svg.active .svg-elem-10 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-11 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.5s;
  }
  
  svg.active .svg-elem-11 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-12 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 3.8s;
  }
  
  svg.active .svg-elem-12 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-13 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.1s;
  }
  
  svg.active .svg-elem-13 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-14 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.4s;
  }
  
  svg.active .svg-elem-14 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-15 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 4.7s;
  }
  
  svg.active .svg-elem-15 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-16 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 5s;
  }
  
  svg.active .svg-elem-16 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-17 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.3s;
  }
  
  svg.active .svg-elem-17 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-18 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.6s;
  }
  
  svg.active .svg-elem-18 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-19 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 5.8999999999999995s;
  }
  
  svg.active .svg-elem-19 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-20 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.2s;
  }
  
  svg.active .svg-elem-20 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-21 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.5s;
  }
  
  svg.active .svg-elem-21 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-22 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 6.8s;
  }
  
  svg.active .svg-elem-22 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-23 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.1s;
  }
  
  svg.active .svg-elem-23 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-24 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.3999999999999995s;
  }
  
  svg.active .svg-elem-24 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-25 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 7.699999999999999s;
  }
  
  svg.active .svg-elem-25 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-26 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 8s;
  }
  
  svg.active .svg-elem-26 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-27 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.3s;
  }
  
  svg.active .svg-elem-27 {
    fill: rgb(44, 36, 33);
  }
  
  svg .svg-elem-28 {
    fill: transparent;
    transition: fill 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55) 8.6s;
  }
  
  svg.active .svg-elem-28 {
    fill: rgb(44, 36, 33);
  } */
  