@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
body {
  margin: 0;
  padding: 0;
  background-color: #f9f7f3;
  font-family: geom;
  font-weight: 400;
}

/* Common Style */

@media (min-width: 1536px) {
  .container {
      max-width: 1300px ;
  }
}

.border-l-6 {
  border-left-width: 6px;
}


.imageSlider .slick-list .slick-track{
  display: flex;
  gap: 20px;
}
/* .imageMarquee .rfm-marquee{
  gap: 30px;
} */

.portfolio-slider .slick-prev, 
.portfolio-slider .slick-next{
  width: 28px;
  height: 28px;
  top: 130px;
  left: 35%;
  z-index: 2;
}
.portfolio-slider .slick-next {
  right: inherit;
  margin-left: 40px;
}
.clientSlider .slick-prev,
.clientSlider .slick-next{
  width: 22px;
  height: 22px;
  top: inherit;
  left: 0;
  bottom: -25px;
  z-index: 2;
}
.clientSlider .slick-next {
  right: inherit;
  margin-left: 35px;
}

.active-link::before {
 content: '';
  position: absolute;
  left: 0;
  right: 28px;
  bottom: -8px;
  height: 3px;
  background-color: #FFD600; 
}


  .btn-borer-hover::before,
  .btn-borer-hover::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #000;
    z-index: 2;
    position: absolute;
    transition: width 0.35s;
  }
  .btn-borer-hover::before {
    top: 0;
    right: 0;
  }
  .btn-borer-hover::after {
    bottom: 0;
    left: 0;
  }
  .btn-borer-hover:hover::before,
  .btn-borer-hover:hover::after {
    width: 0%;
    transition: width 0.2s 0.2s ease-out;
  }
  .btn-borer-hover span::before,
  .btn-borer-hover span::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #000;
    z-index: 2;
    position: absolute;
    transition: height 0.25s;
  }
  .btn-borer-hover span::before {
    bottom: 0;
    right: 0;
  }
  .btn-borer-hover span::after {
    top: 0;
    left: 0;
  }
  .btn-borer-hover:hover span::before,
  .btn-borer-hover:hover span::after {
    height: 0%;
  }

.solution-line {
  position: relative;
}
.solution-line:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 158px;
  background-color: #000;
  right: 19px;
  top: 8px;
}